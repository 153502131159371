import {
    faFacebook,
    faInstagram,
    faTiktok,
} from "@fortawesome/free-brands-svg-icons";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React from "react";
import TitleShadowComponent from "../../../titleShadow/components/TitleShadowComponent";
import "../style/Grid5Style.scss";

const Grid5Component = ({ brandData, openLogin }) => {
    const [isConnected, setIsConnected] = React.useState(
        brandData.is_connection_request_sent_or_accepted
    );

    const handleRequestConnection = (sellerId) => {
        axios
            .post(
                `${process.env.REACT_APP_NEW_API_URL}/v1/connections/seller/${sellerId}`
            )
            .then((response) => {
                if (response.data.data) {
                    setIsConnected(true);
                }
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    localStorage.setItem("redirectAfterLogin", false);
                    openLogin();
                }
            });
    };

    return (
        <div className="grid5">
            <div className="leftGrid">
                <div className="brandImageContainer">
                    <a href={brandData.brand_page_url}>
                        {brandData.list_image !== undefined ? (
                            <img
                                src={brandData.list_image}
                                alt="Brand image"
                                className="brand-image"
                            />
                        ) : (
                            <div className="image-placeholder">
                                <p>{brandData.name}</p>
                            </div>
                        )}
                    </a>

                    <div
                        className="heartIconContainer"
                        onClick={() => handleRequestConnection(brandData.id)}
                        title={
                            isConnected
                                ? `You are connected with ${brandData.name}`
                                : `Connect to ${brandData.name}`
                        }
                    >
                        <img
                            src={
                                isConnected
                                    ? "/images/heart-filled.svg"
                                    : "/images/heart.svg"
                            }
                            alt={
                                isConnected
                                    ? `You are connected with ${brandData.name}`
                                    : `Connect to ${brandData.name}`
                            }
                            className="heart-icon"
                        />
                    </div>
                </div>
            </div>

            <div className="rightGrid">
                <div className="infoComponent">
                    <a className="link" href={brandData.brand_page_url}>
                        <TitleShadowComponent
                            position="left titleShadowContainer"
                            type="h2"
                            tagClass="titleGrid5"
                        >
                            {brandData.name}
                        </TitleShadowComponent>
                    </a>

                    <div className="containerInfoStatic">
                        <div className={`blockInfo`}>
                            <h4 className="title">CATEGORIES</h4>

                            <p className="text">
                                {!!brandData.categories
                                    ? brandData.categories
                                    : "-"}
                            </p>
                        </div>
                        <div className="alignLeft">
                            {/* <div className={`blockInfo`}>
                                <h4 className="title">YEAR ESTABLISHED</h4>

                                <p className="text">
                                    {brandData.year_established}
                                </p>
                            </div> */}

                            <div className={`blockInfo`}>
                                <h4 className="title">SOCIAL LINKS</h4>

                                <div className="socialIcons">
                                    {brandData.site_url && (
                                        <a
                                            href={brandData.site_url}
                                            target="_blank"
                                            className="icon"
                                        >
                                            <FontAwesomeIcon icon={faGlobe} />
                                        </a>
                                    )}

                                    {brandData.facebook_url && (
                                        <a
                                            href={brandData.facebook_url}
                                            target="_blank"
                                            className="icon"
                                        >
                                            <FontAwesomeIcon
                                                icon={faFacebook}
                                            />
                                        </a>
                                    )}

                                    {brandData.instagram_url && (
                                        <a
                                            href={brandData.instagram_url}
                                            target="_blank"
                                            className="icon"
                                        >
                                            <FontAwesomeIcon
                                                icon={faInstagram}
                                            />
                                        </a>
                                    )}

                                    {brandData.tiktok_url && (
                                        <a
                                            href={brandData.tiktok_url}
                                            target="_blank"
                                            className="icon"
                                        >
                                            <FontAwesomeIcon icon={faTiktok} />
                                        </a>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="containerButtonText">
                        <a href={brandData.brand_page_url}>
                            <p className={`textgrid5`}>
                                {brandData.landing_description}
                            </p>
                        </a>
                    </div>
                </div>

                <div className="actionsContainer">
                    <button className="secondary">
                        <a
                            href={`${brandData.brand_page_url}?action=send-a-message`}
                        >
                            send a message
                        </a>
                    </button>

                    <button className="secondary">
                        <a
                            href={`https://blancfashion.pipedrive.com/scheduler/ql9z4vs59/meeting-at-coterie-ny`}
                            target="_blank"
                        >
                            book a meeting
                        </a>
                    </button>

                    {brandData.has_active_collection && (
                        <button className="primary">
                            <a href={`${brandData.order_now_url}`}>order now</a>
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Grid5Component;
