import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import "react-id-swiper/lib/styles/scss/swiper.scss";
import NavbarComponent from "../../../landing/shared/navbar/components/NavbarComponent";
import Breadcomb from "../../../menu/components/Breadcomb";
import MenuComponent from "../../../menu/components/MenuComponent";
import SelectInput from "../../../shared/button/components/SelectInput";
import FooterComponent from "../../../shared/footer/components/FooterComponent";
import Grid5Component from "../../../shared/grids/grid5/components/Grid5Component";
import LightBoxComponent from "../../../shared/lightbox/components/LightBoxComponent";
import LoginComponent from "../../../shared/Login/components/LoginComponent";
import RegisterModalComponent from "../../../shared/registermodal/components/RegisterModalComponent";
import SpinnerComponent from "../../../shared/spinner";
import "../../../shared/style/home/HomeStyle.scss";
import "../styles/LandingPageSpecialProject.scss";

const LandingPageSpecialProjects = () => {
    const [projectData, setProjectData] = useState([]);
    const [projectSellers, setProjectSellers] = useState([]);
    const [paginationData, setPaginationData] = useState([]);
    const [segmentData, setSegmentData] = useState([]);
    const [categoriesData, setCategoriesData] = useState([]);
    const [segments, setSegments] = useState([]);
    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [sellersPage, setSellersPage] = useState(1);
    const [showRegister, setShowRegister] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    const [registerCompanyType, setRegisterCompanyType] = useState("");

    const pathname = window.location.pathname;
    let projectName = "";

    if (pathname.includes("/project/")) {
        const pathUrlSegments = pathname.split("/");
        const indexProject = pathUrlSegments.indexOf("project");
        projectName = pathUrlSegments[indexProject + 2];
    }

    const openLogin = (eventUrl = "") => {
        if (eventUrl !== "") {
            localStorage.setItem("vincular_evento", `/${eventUrl}`);
        }
        setShowLogin(true);
    };

    const closeLogin = () => {
        localStorage.removeItem("redirectBrand");
        localStorage.removeItem("vincular_evento");
        setShowLogin(false);
    };

    const openRegister = (companyType = "", eventUrl = "") => {
        if (eventUrl !== "") {
            localStorage.setItem("defaultEvent", `/${eventUrl}`);
        }

        setRegisterCompanyType(companyType);
        setShowRegister(true);
    };

    const closeRegister = () => {
        localStorage.removeItem("defaultEvent");
        setShowRegister(false);
    };

    const getProjectData = () => {
        axios
            .get(
                `${process.env.REACT_APP_NEW_API_URL}/v2/segment/${projectName}`
            )
            .then((response) => {
                setProjectData(response.data.data);
            });
    };

    const getProjectSellers = (isLoadingMore = false) => {
        setIsLoading(true);

        axios
            .get(
                `${process.env.REACT_APP_NEW_API_URL}/v2/segment/${projectName}/sellers?page=${sellersPage}`,
                {
                    params: {
                        categories,
                        segments,
                    },
                }
            )
            .then((response) => {
                if (isLoadingMore) {
                    setProjectSellers((prevSellers) => [
                        ...prevSellers,
                        ...response.data.data,
                    ]);
                } else {
                    setProjectSellers(response.data.data);
                }

                setPaginationData(response.data.pagination);
            })
            .finally(() => setIsLoading(false));
    };

    const handleLoadMoreSellers = () => {
        setSellersPage((currentSellersPage) => currentSellersPage + 1);
    };

    const getOptionsForFilters = () => {
        axios({
            method: "get",
            url: `${process.env.REACT_APP_NEW_API_URL}/v2/segments`,
        }).then((response) => {
            setSegmentData(response.data.data);
        });

        axios({
            method: "get",
            url: `${process.env.REACT_APP_NEW_API_URL}/v2/categories`,
        }).then((response) => {
            setCategoriesData(response.data.data);
        });
    };

    const handleFilter = (checkedItems, type) => {
        setSellersPage(1);

        const types = {
            1: "segments",
            2: "categories",
        };

        const params = new URLSearchParams(window.location.search);

        params.set(types[type], checkedItems.join(","));
        window.history.replaceState(
            {},
            "",
            `${window.location.pathname}?${params.toString()}`
        );

        if (type === 1) {
            setSegments(checkedItems);
        }

        if (type === 2) {
            setCategories(checkedItems);
        }
    };

    useEffect(() => {
        getProjectData();
        getProjectSellers();
        getOptionsForFilters();
    }, []);

    useEffect(() => {
        if (sellersPage !== 1) {
            getProjectSellers(true);
        }
    }, [sellersPage]);

    useEffect(() => {
        getProjectSellers();
    }, [categories, segments]);

    return (
        <Fragment>
            {window.localStorage.getItem("user") === null ? (
                <NavbarComponent showLogin={() => openLogin()} />
            ) : (
                <MenuComponent />
            )}

            <Breadcomb
                links={[
                    { text: projectData.name, link: false },
                    { text: "All brands", link: false },
                ]}
            />

            <div className={`theBrandHeaderWrapper`}>
                <div
                    className="theBrandHeader"
                    style={{
                        backgroundImage: `url(${
                            projectData.hero && projectData.hero.image
                        })`,
                    }}
                >
                    <span className={`project-title`}>
                        {projectData.hero && projectData.hero.title}
                        <br />
                        <span className={`project-subtitle`}>
                            {projectData.hero &&
                                projectData.hero
                                    .event_segment_title_about_project}
                        </span>
                    </span>
                </div>
            </div>

            <div className="filtersContainer">
                <div className="relative">
                    <SelectInput
                        title={"SEGMENTS"}
                        data={segmentData}
                        handleFilter={handleFilter}
                        typeOf={1}
                    />
                </div>

                <div className="relative">
                    <SelectInput
                        title={"CATEGORIES"}
                        data={categoriesData}
                        handleFilter={handleFilter}
                        typeOf={2}
                    />
                </div>
            </div>

            <div className="brandsContainer">
                {isLoading && (
                    <div className="loadingContainer">
                        <SpinnerComponent />
                    </div>
                )}

                {!isLoading && !projectSellers.length && (
                    <div>
                        <p
                            style={{
                                textAlign: "center",
                                fontSize: "1.3rem",
                            }}
                        >
                            We couldn't find any brand matching your filters at
                            this moment.
                        </p>
                    </div>
                )}

                {projectSellers &&
                    projectSellers.map((brandData) => {
                        return (
                            <Grid5Component
                                brandData={brandData}
                                openLogin={openLogin}
                            />
                        );
                    })}

                {paginationData && paginationData.has_more && (
                    <div
                        className="loadMoreButtonContainer"
                        onClick={() => handleLoadMoreSellers()}
                    >
                        <button>
                            {isLoading ? (
                                <SpinnerComponent />
                            ) : (
                                <>Load more...</>
                            )}
                        </button>
                    </div>
                )}
            </div>

            {showRegister && (
                <RegisterModalComponent
                    companyType={registerCompanyType}
                    close={() => {
                        closeRegister();
                        setShowRegister(false);
                    }}
                />
            )}
            {showLogin && (
                <LightBoxComponent
                    isOpen={showLogin}
                    handleClick={() => closeLogin()}
                    handleEventClose={() => closeLogin()}
                >
                    <LoginComponent
                        closeRegister={closeRegister}
                        OpenRegister={openRegister}
                    />
                </LightBoxComponent>
            )}
            <FooterComponent />
        </Fragment>
    );
};

export default withTranslation()(LandingPageSpecialProjects);
