import TagManager from '@sooro-io/react-gtm-module';
import { default as Axios, default as axios } from "axios";
import mixpanel from "mixpanel-browser";
import querystring from "querystring";

const API_CLIENT_ID = "V64QLUP0V9H9E1rdPTGcP6wvettYiM6cLjzTcFzj";
const API_CLIENT_SECRET =
  "0x0cwqdcrP9qNsRi9VRErCw1uzd1mrK8LOV3YL7rXWEvcKL5JO6EZsPGcVu8AEH8ni5WpXTnfkYVchST9d9Qn6owwTkxfgMJfyI2mgk5nWaxvFe3XIWMjdMxEY8L2cEU";
let urlWithoutSearchParams = '';


class AuthenticationService {

  login(userData) {
    const data = querystring.stringify({
      grant_type: "password",
      username: userData.email,
      password: userData.password,
    });

    const auth = { username: API_CLIENT_ID, password: API_CLIENT_SECRET };
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": "*",
    };
    const config = {
      auth,
      headers,
    };

    return new Promise(async (resolve, reject) => {
      try {
        let response = await axios.post("o/token/", data, config);

        if (!response.data.password_changed) {
          response.data.error = "password_not_changed";
          return reject(response.data);
        }

        let responseNewApi = await axios.post(
          `${process.env.REACT_APP_NEW_API_URL}/v1/auth/login`,
          data
        );

        await this.setTokens(response.data, responseNewApi.data);

        if (responseNewApi.status === 200) {
          const infoUser = await this.getInfoUser();

          if (
            process.env.REACT_APP_MIXPANEL_ENABLED === true ||
            process.env.REACT_APP_MIXPANEL_ENABLED === "true"
          ) {
            mixpanel.identify(`${infoUser.id}`);
            mixpanel.track("Login");

            mixpanel.people.set({
              $email: infoUser.email,
              $created: infoUser.created_at,
              $distinct_id: `${infoUser.id}`,
              $first_name: infoUser.first_name,
              $last_name: infoUser.last_name,
              $name: `${infoUser.first_name} ${infoUser.last_name}`,
              buyer:
                infoUser.entity_stype === "buyer" ? infoUser.buyer.name : null,
              buyer_id:
                infoUser.entity_stype === "buyer" ? infoUser.buyer.id : null,
              seller:
                infoUser.entity_stype === "seller"
                  ? infoUser.seller.name
                  : null,
              seller_id:
                infoUser.entity_stype === "seller" ? infoUser.seller.id : null,
              segments: infoUser.event_segments.map((item) => item.segment),
              hide_from_retailer:
                (infoUser &&
                  infoUser.buyer &&
                  infoUser.buyer.hide_find_retailers) ||
                false,
            });
          }
          // Google Tag Manager event: login
          const tagManagerArgs = {
            dataLayer: {
              event: 'login',
              user_id: `${infoUser.id}`,
              user_type: `${infoUser.entity_stype}`,
            },
          };
          TagManager.dataLayer(tagManagerArgs);
          this.redirectAfterLogin();
        }
        resolve(responseNewApi);
      } catch (ex) {
        this.reportLoginError({
          grant_type: "password",
          username: userData.email,
          password: userData.password,
        });

        if (ex.response && ex.response.data) {
          reject(ex.response.data);
        }
      }
    });
  }

  register() { }

  reportLoginError(data) {
    return axios.post("api/login-error/", data);
  }

  preRegister(userData) {
    return axios.post("api/preregistration/", querystring.stringify(userData), {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": "*",
      },
    });
  }

  teste(userData) {
    axios
      .post("api/preregistration/", querystring.stringify(userData), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        // console.log(response, 123)
      });
  }

  async forgotPassword(userEmail) {
    return new Promise(async (resolve, reject) => {
      try {
        let response = await axios.post("api/recovery/", { email: userEmail });
        resolve(response);
      } catch (ex) {
        reject(ex);
      }
    });
  }

  async setTokens(response, responseNew = null) {
    localStorage.setItem("access_token", response.access_token);

    if (responseNew && responseNew.access_token) {
      localStorage.setItem("new_access_token", responseNew.access_token);
    }

    localStorage.setItem("refresh_token", response.refresh_token);
  }

  async setTokensURL(response) {
    localStorage.setItem("new_access_token", response.access_token);
  }

  logout() {
    window.localStorage.clear();
    window.location.href = "/";
  }

  redirectAfterLogin() {
    if (localStorage.getItem('redirectAfterLogin') === 'false') {
        localStorage.removeItem('redirectAfterLogin');
        return window.location.reload();
    }

    const params = new URLSearchParams(window.location.search);
    let next = params.get("next", "");
    let params_string = "";

    for (var key of params.keys()) {
      let value = params.get(key);
      if (key !== "next") {
        params_string += `&${key}=${value}`;
      }
    }

    if (localStorage.getItem("vincular_evento")) {
      next = localStorage.getItem("vincular_evento");
    }

    if (localStorage.getItem("redirectBrand")) {
      if (!localStorage.getItem("vincular_evento")) {
        next = localStorage.getItem("redirectBrand");
        localStorage.removeItem("redirectBrand");
      }
    }

    if (localStorage.getItem("seller_slug")) {
      next = localStorage.getItem("seller_slug");
    }
    let urlIncludeSlug = !(window.location.href.includes(next))

    if (params_string) {
      window.location.href = `${next}?${params_string}`;
    } else if (next && urlIncludeSlug) {
      window.location.href = `${next}`;
    } else {
      if (window.location.pathname.includes('/register')) {
        const newPathname = window.location.pathname.replace('/register', '/collections');
        return window.location.href = newPathname;
      } else {
        window.location.href = "/";
      }
    }

    localStorage.removeItem("vincular_evento");
  }

  async getInfoUser() {

    const headers = {
      Authorization: `Bearer ${window.localStorage.getItem("new_access_token")}`,
      "Access-Control-Allow-Origin": "*",
    };
    const configUser = { headers };

    let response = await axios.get(
      `${process.env.REACT_APP_NEW_API_URL}/v1/auth/user-data`,
      configUser
    );

    let r = response.data;

    if (r.first_name === "" || !r.first_name) {
      localStorage.clear();
      window.location.href = "/";
      return;
    }

    let user = {};
    user.first_name = r.first_name;
    user.last_name = r.last_name;
    user.id = r.id;
    user.entity_type = r.entity_type;
    user.entity_stype = r.entity_stype;
    user.entity_id = r.entity_id;
    user.email = r.email;
    user.phone = r.phone;
    user.welcome_msg = r.welcome_msg;
    user.buyer_locations = r.buyer_locations;
    user.user_hash = r.user_hash;
    user.is_staff = r.is_staff;
    user.allow_auto_add_one_unit_cart = r.allow_auto_add_one_unit_cart;

    let buyer = r.buyer;

    localStorage.setItem("userData", JSON.stringify(user));
    localStorage.setItem("buyerData", JSON.stringify(buyer));
    localStorage.setItem("id", response.data.id);
    localStorage.setItem("user", response.data.first_name);
    localStorage.setItem("user_type", response.data.entity_type);
    localStorage.setItem("user_stype", response.data.entity_stype);
    localStorage.setItem("seller_slug", response.data.seller_slug || "");
    localStorage.setItem("email", response.data.email);
    localStorage.setItem("show_welcome_msg", response.data.welcome_msg);

    let userEventSegments = [];

    if (r.event_segments.length > 0) {
      r.event_segments.map((item) => {
        return userEventSegments.push(item.segment);
      });
    }

    localStorage.setItem(
      "user.event_segments",
      JSON.stringify(userEventSegments)
    );

    return r;
  }

  async loginByToken() {
    urlWithoutSearchParams = new URL(window.location.href);
    urlWithoutSearchParams.searchParams.delete("token")

    await this.setTokensURL({ access_token: localStorage.getItem("temp_token") });
    await localStorage.removeItem("temp_token");

    try {
      await Axios.get(`${process.env.REACT_APP_NEW_API_URL}/v1/auth/me`);

      await this.getInfoUser();

      await new Promise(resolve => setTimeout(resolve, 0));

      setTimeout(() => {
        this.redirectAfterTokenLogin();
      }, 0);
      return;
    } catch (ex) {

    }

    try {
      const refresh = await Axios.post(`${process.env.REACT_APP_NEW_API_URL}/v1/auth/refresh`);

      await this.setTokensURL({ access_token: refresh.data.access_token });
      await localStorage.removeItem("temp_token");

      await this.getInfoUser();

      await new Promise(resolve => setTimeout(resolve, 0));

      setTimeout(() => {
        this.redirectAfterTokenLogin();
      }, 0);
      
    } catch (ex) {

    }
  }

  async manageToken() {
    const newAccessToken = localStorage.getItem('new_access_token');
    if(newAccessToken) {
      try {
        await axios.get(`${process.env.REACT_APP_NEW_API_URL}/v1/auth/me`);

      } catch (error) {
        try {
          const getRefreshedToken = await axios.post(`${process.env.REACT_APP_NEW_API_URL}/v1/auth/refresh`);
          const refreshedToken = getRefreshedToken.data.access_token
      
          if (refreshedToken) {
            localStorage.setItem("new_access_token", refreshedToken);
          }
    
        } catch (error) {
          console.error(error)
          window.localStorage.clear();
          window.location.href = "/";
        }
      }
    }

    if(!newAccessToken) {
      const url = window.location.href;
      if(url.includes('collections')) {
        const splitedUrl = url.split('/');
        const brandSlug = splitedUrl[3];
        window.location.href = `/${brandSlug}/register`
      } else if(url.includes('product')) {
        try {
          const splitedUrl = url.split('/');
          const response = await axios.get(`${process.env.REACT_APP_NEW_API_URL}/v1/products/${splitedUrl[4]}/seller`);

          if(response.status === 200 && response.data) {
            window.location.href = `/${response.data.data.slug}/register`
          } else {
            window.location.href = "/"
          }
          
        } catch (error) {
          console.error(error)
          window.location.href = "/"
        }
      }
    }
  }

  async isLogged() {
    const newToken = localStorage.getItem("new_access_token");
    if (!newToken) {
      window.localStorage.clear();
    } else {
      return newToken
    }
  }

  async redirectAfterTokenLogin() {
    setTimeout(() => {
      window.location.replace(urlWithoutSearchParams);
    }, 1000);
  }
}



export default new AuthenticationService();
