import axios from "axios";
import mixpanel from "mixpanel-browser";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { saveData } from "../../../../data/pages/connect/ConnectActions";
import NavbarComponent from "../../../landing/shared/navbar/components/NavbarComponent";
import Breadcomb from "../../../menu/components/Breadcomb";
import MenuComponent from "../../../menu/components/MenuComponent";
import FooterComponent from "../../../shared/footer/components/FooterComponent";
import LightBoxComponent from "../../../shared/lightbox/components/LightBoxComponent";
import LoadingComponent from "../../../shared/loading/components/LoadingComponent";
import LoginComponent from "../../../shared/Login/components/LoginComponent";
import "../../../shared/style/home/HomeStyle.scss";
import TheBrandComponentLightTemplate from "../../../thebrand/brand/components/TheBrandComponentLightTemplate";
import CollectionViewComponent from "../../../thebrand/collection/components/CollectionViewComponent";
import "../../styles/global.scss";

const CollectionViewRender = ({ match, brandData }) => {
    return (
        <CollectionViewComponent
            sellerSlug={match.params.slug}
            collectionId={match.params.collectionId}
            brandData={brandData}
        />
    );
};

class BrandPageLightTemplateViewComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            showLogin: false,
            messageCatch: "",
            connectionLoading: false,
            errorConnection: false,
            userType:
                window.localStorage.getItem("userData") &&
                JSON.parse(window.localStorage.getItem("userData"))
                    .entity_stype &&
                JSON.parse(window.localStorage.getItem("userData"))
                    .entity_stype,
        };
    }

    readMore(isReadMore) {
        this.setState({
            readMore: isReadMore,
        });
    }

    getBrandData() {
        const urlParams = new URLSearchParams(window.location.search);
        const brandPageDraft = urlParams.get("brand_page_draft");
        let query = "";
        if (brandPageDraft) {
            query = "?brand_page_draft=1";
        }

        axios
            .get(
                `${process.env.REACT_APP_NEW_API_URL}/v1/sellers/${this.props.match.params.slug}/brand-page${query}`,
                this.headers
            )
            .then((response) => {
                return response.data;
            })
            .then((response) => ({
                ...response,
                history: {
                    ...response.history,
                    info_block: [
                        ...response.history.info_block.map((item) => ({
                            ...item,
                            images: [
                                ...item.images.map((imageItem) => ({
                                    ...imageItem,
                                    featured_image: null,
                                })),
                            ],
                        })),
                    ],
                },
                the_brand: {
                    ...response.the_brand,
                    info_block: [
                        ...response.the_brand.info_block.map(
                            (theBrandInfoBlock) => ({
                                ...theBrandInfoBlock,
                                images: [
                                    ...theBrandInfoBlock.images.map(
                                        (theBrandInfoBlockImage) => ({
                                            ...theBrandInfoBlockImage,
                                            featured_image: null,
                                        })
                                    ),
                                ],
                            })
                        ),
                    ],
                },
            }))
            .then((response) => {
                this.setState({
                    brandData: response,
                    loading: false,
                });

                if (
                    process.env.REACT_APP_MIXPANEL_ENABLED === true ||
                    process.env.REACT_APP_MIXPANEL_ENABLED === "true"
                ) {
                    if (window.localStorage.getItem("userData")) {
                        mixpanel.identify(
                            `${
                                JSON.parse(
                                    window.localStorage.getItem("userData")
                                ).id
                            }`
                        );
                    }
                    mixpanel.track("Seller Page", {
                        seller: response.name,
                        seller_id: response.id,
                        content: "MAKE A BUY",
                    });
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 403) {
                    this.setState({
                        messageCatch:
                            "Sorry, but the brand you are trying to access is not available",
                        loading: false,
                    });
                } else {
                    this.setState({
                        messageCatch:
                            "Sorry, we could not get the brand you are searching for",
                        loading: false,
                    });
                }
            });
    }

    handleRequestConnection() {
        const brandInfos = this.state.brandData;
        this.setState({
            connectionLoading: true,
        });
        axios
            .post(
                `${process.env.REACT_APP_NEW_API_URL}/v1/connections/seller/${brandInfos.id}`
            )
            .then((response) => {
                if (response.data.data) {
                    this.props.saveData({
                        data: response.data.data,
                    });
                    this.setState({
                        connectionLoading: false,
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    errorConnection: true,
                    connectionLoading: false,
                });
            })
            .finally(() => {
                if (this.state.errorConnection === true) {
                    setTimeout(() => {
                        this.setState({
                            errorConnection: false,
                        });
                    }, 2000);
                }
            });
    }

    handleAcceptConnection() {
        const { data } = this.props.data;

        this.setState({
            connectionLoading: true,
        });

        axios
            .put(`${process.env.REACT_APP_NEW_API_URL}/v1/connections/accept`, {
                connection_id: data.id,
            })
            .then((response) => {
                if (response.data.data) {
                    this.props.saveData({
                        data: response.data.data,
                    });
                    this.setState({
                        connectionLoading: false,
                    });
                }
            });
    }

    componentDidMount() {
        this.getBrandData();
    }

    render() {
        const { data } = this.props.data;

        const brandDataState = this.state.brandData;
        const sellerSlug = this.props.match.params.slug;
        let categoriesFilter = window.localStorage.getItem(
            "brands_last_filter_category_url"
        );

        let brandCategory = false;
        let brandCategoryFilter = false;

        const { t } = this.props;

        let collectionIdFromUrl = null;
        let collectionFromTheBrand = null;

        if (this.state.brandData && this.state.brandData.slug) {
            const matches = window.location.pathname.match(
                new RegExp(
                    `^/${this.state.brandData.slug}/collections/\\d{1,}$`,
                    "gi"
                )
            );

            if (matches) {
                collectionIdFromUrl = matches[0].split("/")[3];

                collectionFromTheBrand =
                    this.state.brandData.the_brand.collections_filter.find(
                        (c) => c.id == collectionIdFromUrl
                    );
            }
        }

        const openLogin = (eventUrl = "") => {
            if (eventUrl !== "") {
                localStorage.setItem("vincular_evento", `/${eventUrl}`);
            }

            this.setState({
                showLogin: true,
            });
        };

        const closeLogin = () => {
            localStorage.removeItem("redirectBrand");
            localStorage.removeItem("vincular_evento");
            this.setState({
                showLogin: false,
            });
        };

        return (
            <div>
                {this.state.errorConnection === true && (
                    <div className="errorConectionPopUp">Connection failed</div>
                )}
                <div className="header-menu-wrapper">
                    {window.localStorage.getItem("user") === null ? (
                        <NavbarComponent showLogin={() => openLogin()} />
                    ) : (
                        <MenuComponent />
                    )}

                    <Breadcomb
                        links={[
                            {
                                text: t("Our Brands", "Our Brands"),
                                link: true,
                                to: "/brands",
                            },
                            brandCategory,
                            brandCategoryFilter,
                            {
                                text:
                                    this.state.brandData &&
                                    this.state.brandData.name,
                                link: false,
                            },
                        ]}
                    />
                </div>
                {this.state.loading ? (
                    <LoadingComponent />
                ) : this.state.messageCatch !== "" ? (
                    <div className="messageCatchBrand">
                        <h1>{this.state.messageCatch}</h1>
                    </div>
                ) : this.state.brandData ? (
                    <div>
                        <div
                            className={`theBrandHeaderWrapper ${
                                this.state.brandData.meta !== null &&
                                this.state.brandData.meta.draft_changes.seller.includes(
                                    "cover_image"
                                )
                                    ? "border-marker"
                                    : ""
                            }`}
                        >
                            <div
                                className="theBrandHeader"
                                style={{
                                    backgroundImage: `url(${this.state.brandData.cover_image_thumbnail})`,
                                }}
                            >
                                <span
                                    className={`brand-title ${
                                        this.state.brandData.meta !== null &&
                                        this.state.brandData.meta.draft_changes.seller.includes(
                                            "name"
                                        )
                                            ? "border-marker"
                                            : ""
                                    }`}
                                >
                                    {this.state.brandData.name}
                                </span>
                            </div>
                        </div>
                        <main id="main">
                            <TheBrandComponentLightTemplate
                                tags={this.state.brandData.tags}
                                info={true}
                                brandStyleImages={
                                    this.state.brandData.the_brand
                                        .brand_style_images
                                }
                                theBrand={this.state.brandData.the_brand}
                                isReadMore={this.readMore.bind(this)}
                                readMore={this.state.readMore}
                                seller={sellerSlug}
                                brandData={this.state.brandData}
                            />

                            {this.state.showLogin && (
                                <LightBoxComponent
                                    isOpen={this.state.showLogin}
                                    handleClick={() => closeLogin()}
                                    handleEventClose={() => closeLogin()}
                                >
                                    <LoginComponent
                                        closeRegister={this.closeRegister}
                                        OpenRegister={this.openRegister}
                                    />
                                </LightBoxComponent>
                            )}
                            <FooterComponent />
                        </main>
                    </div>
                ) : null}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    data: state.connectEntity.data,
});

const mapDispatchToProps = {
    saveData,
};

BrandPageLightTemplateViewComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(BrandPageLightTemplateViewComponent);

export default withTranslation()(
    withRouter(BrandPageLightTemplateViewComponent)
);
