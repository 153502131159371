import React, { useState, useEffect, Fragment } from 'react';
import '../styles/LandingPageSegments.scss';
import NavbarComponent from '../../../landing/shared/navbar/components/NavbarComponent';
import MenuComponent from '../../../menu/components/MenuComponent';
import { withTranslation } from 'react-i18next';
import LightBoxComponent from '../../../shared/lightbox/components/LightBoxComponent';
import LoginComponent from '../../../shared/Login/components/LoginComponent';
import axios from 'axios';
import FooterComponent from '../../../shared/footer/components/FooterComponent';
import 'react-id-swiper/lib/styles/scss/swiper.scss';
import isMobile from 'is-mobile';
import ReactIdSwiper from 'react-id-swiper/lib/ReactIdSwiper.custom';
import Brand from '../../../landing/shared/ourBrands/components/Brand';
import { Swiper, Navigation, Pagination } from 'swiper/dist/js/swiper.esm';
import Hero from '../../../landing/shared/hero/components/Hero';
import RegisterModalComponent from '../../../shared/registermodal/components/RegisterModalComponent';

const LandingPageSegments = () => {
  const [dataApiBrands, setDataApiBrands] = useState([]);
  const [dataHeader, setDataHeader] = useState([]);
  const [showRegister, setShowRegister] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const [swiper, setSwiper] = useState(null);
  const [registerCompanyType, setRegisterCompanyType] = useState('');
  const pathname = window.location.pathname;
  let projectName = '';

  if (pathname.includes("itastyle")) {
      const pathUrlSegments = pathname.split("/");
      const indexProject = pathUrlSegments.indexOf("project");

      if (indexProject !== -1) {
          pathUrlSegments.splice(indexProject + 1, 0, "special");
      }

      window.location.href = pathUrlSegments.join("/");
  }

  if (pathname.includes('/project/')) {
    const pathUrlSegments = pathname.split('/');
    const indexProject = pathUrlSegments.indexOf('project');
    projectName = pathUrlSegments[indexProject + 1];
  }

  const getDataApi = async () => {
    try {
      const resultBrands = await axios.get(
        `${process.env.REACT_APP_NEW_API_URL}/v1/sellers/landing?events[]=${projectName}`
      );
      const resultHeader = await axios.get(
        `${process.env.REACT_APP_NEW_API_URL}/v1/heroes?events[]=${projectName}`
      );

      setDataApiBrands(resultBrands.data);
      setDataHeader(resultHeader.data.data);
      // Validar se é encontrado alguma heroe ou alguma marca
      if (!resultHeader.data.data || resultHeader.data.data.length === 0) {
        window.location.href = '/';
      }
    } catch (error) {
      console.error(error);
    }
  };

  const openLogin = (eventUrl = '') => {
    if (eventUrl !== '') {
      localStorage.setItem('vincular_evento', `/${eventUrl}`);
    }
    setShowLogin(true);
  };

  const closeLogin = () => {
    localStorage.removeItem('redirectBrand');
    localStorage.removeItem('vincular_evento');
    setShowLogin(false);
  };

  const openRegister = (companyType = '', eventUrl = '') => {
    if (eventUrl !== '') {
      localStorage.setItem('defaultEvent', `/${eventUrl}`);
    }

    setRegisterCompanyType(companyType);
    setShowRegister(true);
  };

  const closeRegister = () => {
    localStorage.removeItem('defaultEvent');
    setShowRegister(false);
  };

  useEffect(() => {
    getDataApi();
  }, []);

  const settings = {
    Swiper,
    modules: [Navigation, Pagination],
    preloadImages: true,
    updateOnImagesReady: true,
    slidesPerGroup: isMobile() ? 2 : 1,
    slidesPerView: isMobile() ? 2 : 4,
    slidesPerColumn: 1,
    watchSlidesProgress: true,
    watchSlidesVisibility: true,
    spaceBetween: 10,
    slideVisibleClass: 'active-slider'
  };

  const next = () => {
    if (swiper) {
      swiper.slideNext();
      setIsBeginning(swiper.isBeginning);
      setIsEnd(swiper.isEnd);
    }
  };

  const previous = () => {
    if (swiper) {
      swiper.slidePrev();
      setIsBeginning(swiper.isBeginning);
      setIsEnd(swiper.isEnd);
    }
  };

  const firstMatchingItem = dataHeader
    ? dataHeader.find(
        (item) =>
          item.event_segment_title_about_project !== '' &&
          item.event_segment_about_project !== ''
      )
    : null;

  return (
    <Fragment>
      {window.localStorage.getItem('user') === null ? (
        <NavbarComponent showLogin={() => openLogin()} />
      ) : (
        <MenuComponent />
      )}
      <div className="mainLpSegments">
        {/* Hero Section */}
        <section className="bannerHeroLp">
          {dataHeader && (
            <Hero
              open={openLogin}
              openRegister={openRegister}
              data={dataHeader}
            />
          )}
        </section>

        {/* About section */}
        {firstMatchingItem && (
          <section className="aboutBrand">
            <div className="container">
              <div class="row">
                <div class="col-40">
                  <div class="boxTittleSection">
                    <h1>
                      {firstMatchingItem.event_segment_title_about_project
                        ? firstMatchingItem.event_segment_title_about_project
                        : firstMatchingItem.event_segment.name}
                    </h1>
                  </div>
                </div>
                <div class="col-60">
                  <div class="textoAbout">
                    <p>{firstMatchingItem.event_segment_about_project}</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
        {dataApiBrands.length > 0 && (
          <section className="brandLp">
            <div className="container">
              <div className="boxTitle">
                <h1>Meet the Brands*</h1>
              </div>
              <div className="boxAllSwiper">
                <div className="slider-wrapper">
                  <div className="slider-lp-custom">
                    <ReactIdSwiper
                      {...settings}
                      getSwiper={(swiperInstance) => setSwiper(swiperInstance)}
                    >
                      {dataApiBrands &&
                        dataApiBrands.map((brand) => (
                          <Brand
                            key={brand.id}
                            brand={brand}
                            eventSegment={brand.event_segments[0]}
                          />
                        ))}
                    </ReactIdSwiper>
                  </div>
                </div>
                <div className="buttons buttons-list">
                  <div
                    className={`swiper-button-prev ${
                      isBeginning && 'swiper-button-disabled'
                    }`}
                    onClick={previous}
                  ></div>
                  <div
                    className={`swiper-button-next ${
                      isEnd && 'swiper-button-disabled'
                    }`}
                    onClick={next}
                  ></div>
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
      {showRegister && (
        <RegisterModalComponent
          companyType={registerCompanyType}
          close={() => {
            closeRegister();
            setShowRegister(false);
          }}
        />
      )}
      {showLogin && (
        <LightBoxComponent
          isOpen={showLogin}
          handleClick={() => closeLogin()}
          handleEventClose={() => closeLogin()}
        >
          <LoginComponent
            closeRegister={closeRegister}
            OpenRegister={openRegister}
          />
        </LightBoxComponent>
      )}
      <FooterComponent />
    </Fragment>
  );
};

export default withTranslation()(LandingPageSegments);
